import React, {Component} from "react";
import '../css/Section.css'

class Section extends Component {

    render() {
        return (
            <div className={"section " + (this.props.isTopSection ? 'top-section' : '')}>
                {this.props.title?(
                    <div>
                        <h2 className="section--title">{this.props.title}</h2>
                        <hr />
                    </div>
                    ):null}
                {this.props.children}
            </div>
        )
    }
}

export default Section;