import React, {Component} from "react";
import Section from './Section';
import '../css/ContractDataSection.css'
import Parser from 'html-react-parser';

class ContractDataSection extends Component {

    render() {
        return (
            <Section isTopSection={this.props.isTopSection} title={this.props.value.title}>
                <p>{Parser(this.props.value.firstParagraph)}</p>
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="contract-data-section-h3">Ansprechpartner</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.name}</p>
                    </div>
                    <div className="col-md-6">
                        <h3 className="contract-data-section-h3">Firma</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.company}</p>
                    </div>
                    <div className="col-md-6">
                        <h3 className="contract-data-section-h3">Anschrift</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.street} {this.props.contractualPartner.houseNumber}</p>
                    </div>
                    <div className="col-md-2">
                        <h3 className="contract-data-section-h3">Postleitzahl</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.zipCode}</p>
                    </div>
                    <div className="col-md-4">
                        <h3 className="contract-data-section-h3">Ort</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.city}</p>
                    </div>
                    <div className="col-md-6">
                        <h3 className="contract-data-section-h3">E-Mail-Adresse</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.email}</p>
                    </div>
                    <div className="col-md-6">
                        <h3 className="contract-data-section-h3">Telefonnummer</h3>
                        <p className="contract-data-section-value">{this.props.contractualPartner.phone}</p>
                    </div>
                </div>
            </Section>
        )
    }
}

export default ContractDataSection;