import React, {Component} from "react";
import '../css/Title.css'

class Title extends Component {

    render() {
        return (
            <div>
                <h1 className="title-h1">{this.props.value.title}</h1>
                {this.props.value.subtitle?(<h2 className="title-h2">{this.props.value.subtitle}</h2>):null}
            </div>
        )
    }
}

export default Title;