import React, {Component} from "react";
import '../css/Checkbox.css'

class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: false};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let checked = !this.state.checked;

        this.props.onChange(this, checked);
        this.setState({checked: checked});
    }

    render() {
        let text = this.props.checkbox.text;
        let id = makeid(5);

        let hasMoreInfo = text.includes("LINK");

        if (hasMoreInfo) {
            let linkText = this.props.checkbox.linkText;

            let insertedLinkText = '<a type="button" className="checkbox-link" data-toggle="modal" data-target="#' + id +  '">' + linkText + '</a>';

            text = text.replace("LINK", insertedLinkText);
        }


        return (
            <div>
                <div className="form-check">
                    <input checked={this.state.checked} onChange={this.handleChange}  className='form-check-input' type="checkbox" required={this.props.required?true:false} />
                    <label className='form-check-label fine-print' dangerouslySetInnerHTML={{ __html: text}} />
                </div>

                {hasMoreInfo
                    ?
                    <div className="modal" id={id}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h4 className="modal-title">{this.props.checkbox.title}</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>


                                <div className="modal-body">
                                    <div>
                                        {this.props.checkbox.type === "pdf" ?
                                            <iframe
                                                src={this.props.checkbox.pdfFile}
                                                width="100%" height="400px"></iframe>
                                            :
                                            <p dangerouslySetInnerHTML={{__html: this.props.checkbox.longText}}/>

                                        }
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>
                : ''}
            </div>
        )
    }
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default Checkbox;