import React, {Component} from "react";
import Section from './Section';

class SignSection extends Component {

    render() {
        return (
            <Section title={this.props.value.title} isTopSection={this.props.isTopSection}>
                <div dangerouslySetInnerHTML={{ __html: this.props.value.text }} />
            </Section>
        )
    }
}

export default SignSection;