import request from 'superagent';
import Swal from 'sweetalert2';

export const seperator = "Y6#G4Sah#T";

export const sortBySortId = (a, b) => {
    if (a.sortId < b.sortId) {
        return -1;
    }
    if (a.sortId < b.sortId) {
        return 1;
    }

    return 0;
};

export const concludeContract = (contractId, signData, checkboxes, contractData, tickedAddOns, billingCycle) => {

    let body = {
        "contractId": contractId,
        "data": signData,
        "checkboxes": checkboxes,
        "contractualPartner": contractData.contractualPartner,
        "tickedAddOns": tickedAddOns,
        "billingCycle": billingCycle
    };

    console.log(JSON.stringify(body));

    request
        .post('https://us-central1-contract-tool-1586930285911.cloudfunctions.net/contract/' + contractId + '/conclude')
        //.post('http://localhost:5001/contract-tool-1586930285911/us-central1/contract/' + contractId + '/conclude')
        .send(body)
        .set('X-API-Key', 'foobar')
        .set('Accept', 'application/json')
        .then(res => {
            if (res.body.success) {
                Swal.fire({
                    title: 'Vertrag wurde geschlossen',
                    icon: 'success'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.body.error,
                });
            }


        });
};