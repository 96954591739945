import React, {Component} from "react";
import '../TextComponentSection.css'

class TextComponentSection extends Component {

    render() {
        return (
            <div className="text-component-section">
                <h3 className="text-component-section-title">{this.props.title}</h3>
                <div className="text-component-section-text" dangerouslySetInnerHTML={{ __html: this.props.text }} />
            </div>
        )
    }
}

export default TextComponentSection;