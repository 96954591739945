
let initState = null;

function data (state = initState, action) {

    if (action.type === "FETCH") {
        return action.data;
    }

    return state;
}

export default data;