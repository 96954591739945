import React, {Component} from "react";

class AddOnCheckbox extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: false};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let checked = !this.state.checked;

        this.props.onChangeAddOnCheckbox(this, checked);
        this.setState({checked: checked});
    }

    render() {
        return (
            <div className="form-check">
                <input checked={this.state.checked} onChange={this.handleChange} className="form-check-input" type="checkbox" name="paymentCycle" id={"addOn_" + this.props.addOn.id}/>
                <label className="form-check-label" htmlFor="exampleRadios1">
                    {this.props.addOn.title}
                </label>
            </div>
        )
    }
}

export default AddOnCheckbox;