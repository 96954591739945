import React, {Component} from 'react';
import Section from './Section';
import Checkbox from "./Checkbox";
import AddOnCheckbox from './AddOnCheckbox'
import {sortBySortId} from '../actions/util'
import { connect } from 'react-redux';
import '../css/SignSection.css';

class SignSection extends Component {

    constructor(props) {
        super(props);

        this.props.value.checkboxes.sort(sortBySortId);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onChangeAddOnCheckbox = this.onChangeAddOnCheckbox.bind(this);
        this.onChangeBillingCycle = this.onChangeBillingCycle.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        let defaultName = this.props.contractualPartner.name?this.props.contractualPartner.name:"";
        let defaultLocation = this.props.contractualPartner.city?this.props.contractualPartner.city:"";

        this.state = {
            name: defaultName,
            location: defaultLocation,
            billingCycle: "biannually",
            tickedAddOns: []
        };
    }

    onChangeName(e) {
        this.setState({name: e.target.value});
    }

    onChangeLocation(e) {
        this.setState({location: e.target.value});
    }

    onChangeCheckbox(e, checked) {
        this.props.onCheckboxChange(e, checked);
    }

    onChangeBillingCycle(e) {
        this.setState({"billingCycle": e.currentTarget.value})
    }

    onChangeAddOnCheckbox(e, checked) {
        let tickedAddOns = this.state.tickedAddOns;

        if (checked)
            tickedAddOns.push(e.props.addOn.id);
        else
            tickedAddOns.splice(tickedAddOns.indexOf(e.props.addOn.id), 1);

        this.setState({ tickedAddOns: tickedAddOns });
        console.log(this.state.tickedAddOns)
    }

    onSubmit() {
        this.props.onSubmit({name: this.state.name, location: this.state.location, tickedAddOns: this.state.tickedAddOns, billingCycle: this.state.billingCycle});
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <Section isTopSection={this.props.isTopSection} title="Zahlungsmodalitäten">
                            <div className="form-check" data-toggle="tooltip" data-placement="right" title="Tooltip on right">
                                <input className="form-check-input" onChange={this.onChangeBillingCycle} type="radio" name="paymentCycle" id="paymentCycle" value="monthly" checked={this.state.billingCycle === "monthly"} />
                                <label className="form-check-label">
                                    Monatlich (+20%)
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={this.onChangeBillingCycle} type="radio" name="paymentCycle" id="paymentCycle" value="quarterly" checked={this.state.billingCycle === "quarterly"} />
                                <label className="form-check-label">
                                    Quartalsweise (+10%)
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={this.onChangeBillingCycle} type="radio" name="paymentCycle" id="paymentCycle" value="biannually" checked={this.state.billingCycle === "biannually"} />
                                <label className="form-check-label">
                                    Halbjährlich
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={this.onChangeBillingCycle} type="radio" name="paymentCycle" id="paymentCycle" value="annually" checked={this.state.billingCycle === "annually"} />
                                <label className="form-check-label">
                                    Jährlich (-20%) <small>Gutschrift i.H.v. 20% auf Kundenkonto nach 1. Zahlung</small>
                                </label>
                            </div>
                        </Section>
                    </div>
                    <div className="col-md-6">
                        <Section isTopSection={this.props.isTopSection} title="Add Ons">
                            {this.props.addOns.map((addOn) => {
                                return (
                                    <AddOnCheckbox key={addOn.id} addOn={addOn} onChangeAddOnCheckbox={this.onChangeAddOnCheckbox} />
                                )
                            })}
                        </Section>
                    </div>
                </div>
                <Section isTopSection="false" title="">
                    <div className="row">
                        <div className="col-md-12">
                            {this.props.value.checkboxes.map((checkbox) => {
                                return (<Checkbox onChange={this.onChangeCheckbox} key={checkbox.cid} checkbox={checkbox} />)
                            })}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        {/*
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Datum</label>
                                <h4>{this.props.value.date?(this.props.value.date):"heute"}</h4>
                            </div>
                        </div>
                        */}
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Ort</label>
                                <input value={this.state.location} type="text" onChange={this.onChangeLocation} className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Unterschrift (Vor- und Nachname)</label>
                                <input type="text" value={this.state.name} onChange={this.onChangeName} className={this.props.nameOfContractualPartner === this.state.name? "form-control border-success" : "form-control border-danger"} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <button onClick={this.onSubmit} type="button" className={"btn btn-primary float-right btn-block" + (this.props.concludeContractButtonClicked ? ' disabled d-none' : '')} >{this.props.value.buttonValue?this.props.value.buttonValue:"🔒 Zahlungspflichtigen Vertrag abschließen"}</button>
                            <p className={"text-center" + (this.props.concludeContractButtonClicked ? '' : ' d-none')}>Der Vertrag wird geschlossen... Bitte warten Sie einen Augenblick.</p>
                        </div>
                    </div>
                </Section>
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {

    }
}

let mapDispatchToProps = {

}

let SignSectionContainer = connect(mapStateToProps, mapDispatchToProps)(SignSection);

export default SignSectionContainer;