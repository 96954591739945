import superagent from 'superagent'

function getContract(contractId) {

    return function (dispatch) {

        //let url = "http://localhost:5001/contract-tool-1586930285911/us-central1/contract/" + contractId;
        let url = "https://us-central1-contract-tool-1586930285911.cloudfunctions.net/contract/" + contractId;
        superagent.get(url)
            .end((err, data) => {
                if (err !== null) {
                    console.error(err);
                    alert("Ein Fehler ist aufgetreten");
                    return;
                }

                if (data === undefined) {
                    // Rest api sends empty data
                    alert("Das Schließen des Vertrags ist gerade nicht möglich. Bitte probieren Sie es später erneut.");
                    return;
                }

                console.log(data.body);

                dispatch({type:"FETCH", data: data.body.data})
            });
    };
}

export default getContract;