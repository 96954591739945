import React, {Component} from "react";

class StartPage extends Component {

    render() {
        return (
            <div>
                <h1>Entweder existiert dieser Vertrags-Token nicht oder der Vertrag wurde schon geschlossen.</h1>
                <a href={"https://elionter.com/impressum"}>Impressum</a> und <a href={"https://elionter.com/datenschutz"}>Datenschutzerklärung</a>
            </div>
        )
    }
}

export default StartPage;