import React, {Component} from "react";
import Section from './Section';
import TextComponentSection from "./TextComponentSection";
import {sortBySortId} from '../actions/util'

class InfoSection extends Component {

    constructor(props) {
        super(props);
        this.props.value.left.sort(sortBySortId);
        this.props.value.right.sort(sortBySortId);
    }

    render() {
        return (
            <Section isTopSection={this.props.isTopSection} title={this.props.value.title}>
                <div className="row">
                    <div className="col-md-6">
                        {this.props.value.left.map((textComponent) => {
                            return (<TextComponentSection key={textComponent.sortId} title={textComponent.title} text={textComponent.text} />)
                        })}
                    </div>
                    <div className="col-md-6">
                        {this.props.value.right.map((textComponent) => {
                            return (<TextComponentSection key={textComponent.sortId} title={textComponent.title} text={textComponent.text} />)
                        })}
                    </div>
                </div>
            </Section>
        )
    }
}

export default InfoSection;