import React, {Component} from 'react';
import getContract from "../actions/rest";
import InfoSection from "./InfoSection";
import SignSection from './SignSection';
import TextSection from './TextSection';
import { connect } from 'react-redux';
import {seperator, sortBySortId} from '../actions/util'
import {concludeContract} from '../actions/util'
import ContractDataSection from './ContractDataSection';
import Title from "./Title";
import Swal from 'sweetalert2';

class ContractPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tickedCheckboxes: [],
            requiredCheckboxes: [],
            contractId: this.props.match.params.contractId,
            concludeContractButtonClicked: false
        };

    }

    componentDidMount() {
        this.props.getContract(this.state.contractId);

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {

        let smallestSectionSortId = 0;

        if (this.props.data) {
            this.props.data.content.sort(sortBySortId);

            this.props.data.content.map((section) => {
                if (section.sortId < smallestSectionSortId && section.type !== "title")
                    smallestSectionSortId = section.sortId;

                return section;
            });
        }

        return (
            <div className="App">
                <div className="container">
                    <form>
                        {this.props.data ? (this.props.data.content.map((section) => {

                            if (section.type === "sign-section") {
                                return (<SignSection addOns={this.props.data.addOns} key={section.sortId} concludeContractButtonClicked={this.state.concludeContractButtonClicked} nameOfContractualPartner={this.props.data.contractualPartner.name} value={section.data}
                                                     isTopSection={section.sortId === smallestSectionSortId}
                                                     onCheckboxChange={this.onCheckboxChange}
                                                     onSubmit={this.onSubmit}
                                                     contractualPartner={this.props.data.contractualPartner}/>);
                            }

                            if (section.type === "title") {
                                return (<Title key={section.sortId} value={section.data}/>)
                            }

                            if (section.type === "contract-data-section") {
                                return (<ContractDataSection key={section.sortId} value={section.data}
                                                             isTopSection={section.sortId === smallestSectionSortId}
                                                             contractualPartner={this.props.data.contractualPartner}/>);
                            }

                            if (section.type === "info-section")
                                return (<InfoSection key={section.sortId} value={section.data}
                                                     isTopSection={section.sortId === smallestSectionSortId}/>);

                            if (section.type === "text-section")
                                return (<TextSection key={section.sortId} value={section.data}
                                                     isTopSection={section.sortId === smallestSectionSortId}/>);

                            return null;
                        })) : null}

                        <p className="text-center mt-3" style={{color: 'gray'}}><a href="https://elionter.com/impressum">Impressum</a> | <a href="https://elionter.com/datenschutz">Datenschutzerklärung</a></p>
                    </form>
                </div>
            </div>
        );
    }

    onCheckboxChange(e, checked) {
        let tickedCheckboxes = this.state.tickedCheckboxes;

        if (checked)
            tickedCheckboxes.push(e.props.checkbox.text + seperator + e.props.checkbox.cid);
        else
            tickedCheckboxes.splice(tickedCheckboxes.indexOf(e.props.checkbox.text + seperator + e.props.checkbox.cid), 1);

        this.setState({ tickedCheckboxes: tickedCheckboxes });
    }


    onSubmit(data) {
        if (data.name !== this.props.data.contractualPartner.name) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Es muss mit dem Namen des Vertragspartners unterschrieben werden!',
            });
            return;
        }

        if (data.location.length <= 3) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Bitte geben Sie einen gültigen Ort an!',
            });
            return;
        }

        let requiredCheckboxes = [];

        this.props.data.content.map((section) => {
            if (section.type === "sign-section") {
                section.data.checkboxes.map(checkbox => {
                    if (checkbox.required)
                        requiredCheckboxes.push(checkbox);
                    return checkbox;
                });
            }
            return section;
        });

        let requiredCheckboxesCid = [];
        let tickedCheckboxesCid = [];

        requiredCheckboxes.map(requiredCheckbox => {
            requiredCheckboxesCid.push(requiredCheckbox.cid);
            return requiredCheckbox;
        });

        this.state.tickedCheckboxes.map((checkbox) => {
            let checkboxId = checkbox.split(seperator)[1];

            tickedCheckboxesCid.push(checkboxId);

            requiredCheckboxesCid.map(requiredCheckboxId => {
                if (requiredCheckboxId === checkboxId) {
                    requiredCheckboxesCid.splice(requiredCheckboxesCid.indexOf(requiredCheckboxId), 1)
                }
                return requiredCheckboxId;
            });

            return checkbox;
        });

        if (requiredCheckboxesCid.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Bitte setzte Haken bei allen notwendigen Checkboxen!',
            });
            return;
        }

        this.setState({
            concludeContractButtonClicked: true
        });

        concludeContract(this.state.contractId, data, tickedCheckboxesCid, this.props.data, data.tickedAddOns, data.billingCycle);

    }

}

let mapStateToProps = (state) => {
    return {
        data: state
    }
};

let mapDispatchToProps = {
    getContract: getContract
};

let ContractContainer = connect(mapStateToProps, mapDispatchToProps)(ContractPage);

export default ContractContainer;